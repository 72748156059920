import $ from 'jquery';

const crGnavMenu = $('.h-gnav-list > a.active').data('navid');

// G Nav - Disp sub list
function dispGnavSubList(obj, id) {
  const navTag = obj.html();
  const insertTag = `<div class="h-gnav-sub-list-wrap" data-navid="${  id  }">${  navTag  }</div>`;

  if ($('.h-gnav-sub-list-wrap').length) $('.h-gnav-sub-list-wrap').remove();
  $('body').append(insertTag);
  $('.h-gnav-sub-list-wrap').fadeIn(400);
}

// G Nav - Hide sub list
function hideGnavSubList() {
  $('.h-gnav-sub-list-wrap').fadeOut(400, function remove() {
    $(this).remove();
  });
}

// G Nav - Insert down Arrow under Cr G Nav
function insertGnavCrArrow(id) {
  const tag = '<div class="cr-gnav-arrow"><i class="fa fa-chevron-down" aria-hidden="true"></i><div>';
  const $t = $(`.h-gnav-list > a[data-navid="${  id  }"]`);

  $t.after(tag);
}

// G Nav - Remove down Arrow under Cr G Nav
function removeGnavCrArrow() {
  $('.cr-gnav-arrow').remove();
}

// G Nav - Change text active color style
function changeGnavActive(id) {
  const $ts = $('.h-gnav-list > a');
  const $t = $(`.h-gnav-list > a[data-navid="${  id  }"]`);

  $ts.removeClass('active');
  $t.addClass('active');

}

// G Nav - Returning original text active color style
function resetGnavActive() {
  const $ts = $('.h-gnav-list > a');
  const $t = $(`.h-gnav-list > a[data-navid="${  crGnavMenu  }"]`);

  $ts.removeClass('active');
  $t.addClass('active');
}

// G Nav - Display SP Nav
function dispSpNav(obj) {
  const navTag = obj.html();
  const insertTags = `<div class="sp-menu-wrap">${  navTag  }</div>`;

  // 本来のヘッダーの上部にSP用のナビを挿入
  $('.HWrapStyleWrap').before(insertTags);
  // 表示されてないケースがあるので表示
  $('.sp-menu-wrap .h-lang .lists').css({'display': 'block'});
  // SP用のナビ領域を表示
  $('.sp-menu-wrap').slideDown(400);
}

// G Nav - Hide and Remove SP Nav
function hideSpNav() {
  $('.HWrap').css({'position': 'fixed'});
  $('.h-menus-sp-selector > a').removeClass('active');
  $('.sp-menu-wrap').slideUp(400, function slideup() {
    $(this).remove();
  });
}

// Lang Nav - Disp List
function dispLangNav() {
  $('.Header .h-lang .target').fadeIn(400);
}

// Lang Nav - Hide List
function hideLangNav() {
  $('.Header .h-lang .target').fadeOut(400);
}

// ** FUNCTION
// --------------------------------
// ナビ関連の状態を全てリセットする
function resetHeaderAll() {
  removeGnavCrArrow();
  hideGnavSubList();
  resetGnavActive();
  hideLangNav();
  hideSpNav();
}

if ($(".HWrap").length) {

  // ** SETTING
  // --------------------------------
  let resizeTimerHeader = false;

  // ** EVENT
  // --------------------------------
  // Resize
  $(window).on('resize orientationchange', function resieze() {
    if (resizeTimerHeader !== false) clearTimeout(resizeTimerHeader);
    resizeTimerHeader = setTimeout(function timeout() {
      resetHeaderAll();
    }, 200);
  });

  // G Nav - Disp and hide secondary navigation
  $('.js-gnav-drop').on('click', function gsGnavDropClick() {
    const crId = $(this).data('navid');
    const navId = $('.h-gnav-sub-list-wrap').data('navid');

    if (!$('.h-gnav-sub-list-wrap').length || navId !== crId) {
      const $t = $(this).parent().find('.h-gnav-sub-list-set');
      dispGnavSubList($t, crId);
      removeGnavCrArrow();
      insertGnavCrArrow(crId);
      changeGnavActive(crId);
    } else {
      removeGnavCrArrow();
      hideGnavSubList();
      resetGnavActive();
    }
    return false;
  });

  // G Nav - Disp and hide SP navigatin
  $('.js-toggle-sp-menu').on('click', function jsToggleClick() {
    if (!$(this).hasClass('active')) {
      $(this).addClass('active');
      dispSpNav($('.h-menus'));
    } else {
      hideSpNav();
    }
    return false;
  });

  // Lang Nav - Disp and hide language navigation
  $('.js-toggle-lang-menu').on('click', function jsToggoleLangClick() {
    const $t = $(this).parent().find('.target');

    if ($t.css('display') === 'none') {
      dispLangNav();
    } else {
      hideLangNav();
    }
    return false;
  });

  // Body Click (All reset)
  $(document).on('click', function documenClick() {
    removeGnavCrArrow();
    hideGnavSubList();
    resetGnavActive();
    hideLangNav();
  });
}
