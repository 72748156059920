import $ from 'jquery';

$(function initConciergeDetail() {
  let map;
  const marker = [];
  const infoWindow = [];
  // Function
  function markerEvent(i) {
    marker[i].addListener('click', function f2() {
      // reset
      for (let j = 0; j < marker.length; j+=1) {
        infoWindow[j].close();
      }
      // open
      infoWindow[i].open(map, marker[i]);
    });
  }

  if ($('#dispConciergeMap').length > 0) {
    $('.ddTrigger a').on('click', function a() {
  		const t = $(this).attr('href');
  		$(t).slideToggle();
  		return false;
  	});

    $('#recalculate').on('click', function recalculateClick() {
      // 現在のパラメータ
      const ptn = window.location.search.match(/ptn=(.*?)(&|$)/)[1];
      const place = window.location.search.match(/place=(.*?)(&|$)/)[1];
      const defaultTourTime = window.location.search.match(/defaultTourTime=(.*?)(&|$)/)[1];
      const startTime = window.location.search.match(/start_time=(.*?)(&|$)/)[1];
      const days = window.location.search.match(/days=(.*?)(&|$)/)[1];
      const speed = window.location.search.match(/speed=(.*?)(&|$)/)[1];
      //      var feeling = location.search.match(/feeling=(.*?)(&|$)/)[1];
      const taste = window.location.search.match(/taste=(.*?)(&|$)/)[1];
      const weight = window.location.search.match(/weight=(.*?)(&|$)/)[1];

      const formAreaArr = [];
      $('.imgCeckSet input[name="exclusion_spot"]:checked').each(function check(index, checkbox) {
        formAreaArr.push($(checkbox).val());
      });

      const params = `${"concierge_detail.html?"
        + "ptn="}${  ptn  }&`
        + `place=${  place  }&`
        + `defaultTourTime=${  defaultTourTime  }&`
        + `start_time=${  startTime  }&`
        + `days=${  days  }&`
        + `speed=${  speed  }&`
      //                 + "feeling=" + feeling + "&"
        + `taste=${  taste  }&`
        + `weight=${  weight  }&`
        + `avoid_ids=${  formAreaArr.toString()}`

      $(this).attr('href',params);
    });// click

    // 地図表示
    /*-----------------------------------
      memo : optionについて
      create関数の引数optで指定できる内容

      [opt.markerType]
      num(default) -> ピンが順番にナンバリングされる。
      normal -> 全て同一の赤いピンになる。
      overlay -> カスタムoverlay要素で表現される。

      [opt.contentsType]
      set(default) -> バルーンオープン時の表示内容が title + picture + addBtn + detailBtn
      pic1 -> バルーンオープン時の表示内容が写真1枚になる。

      [opt.polyline]
      false(default) -> 無し
      true -> マーカー同士に線が引かれる。
    -----------------------------------*/

    // Setting - Vars
    const mapLatLng = new window.google.maps.LatLng({lat: 35.681315, lng: 139.766225}); // provisional
    const mapZoom = 12; // provisional

    // Setting - optの受け取り
    this.markerType = 'num';
    this.contentsType = 'set';
    this.polyline = true;

    // Create - Map
    map = new window.google.maps.Map(document.getElementById('dispConciergeMap'), {
      center: mapLatLng,
      zoom: mapZoom
    });

    const spotList = $(".spotFlowList").data("list");

    // Create - Marker, infoWindow
    for (let i = 0; i < spotList.length; i+=1) {
      const markerLatLng = new window.google.maps.LatLng({lat: spotList[i].lat, lng: spotList[i].lng});
      // markerピンの種類の準備
      const markerImg = spotList[i].icon;

      // infoWindowの中身の準備
      // note : infoWindowのタグ構成はシリーズによって違うので注意
      let baloonContent = '';
      baloonContent += '<div class="gBaloonCmn">';
      baloonContent += '<p class="pic">';
      baloonContent += `<a href="/spot_search/${  spotList[i].id  }">`;
      baloonContent += `<img alt="${  spotList[i].name  }" class="ofi-elm" src="${  spotList[i].img  }">`;
      baloonContent += '</a></p>';
      baloonContent += `<h2 class="ttl">${  spotList[i].name  }</h2>`;
      baloonContent += `<div data-controller="map-marker" data-map-marker-id="${  spotList[i].jc_id  }" data-map-marker-locale="${  $(".spotFlowList").data("locale")  }" class="favorite-spot-cnt btns" data-map-marker-path="${  $("#dispConciergeMap").data("path")  }">`;
      baloonContent += '<form data-target="map-marker.add" data-action="ajax:success->map-marker#added" class="button_to" method="post" action="/ja/my_plan/favorite_spots" data-remote="true">';
      baloonContent += '<button class="btn btn-primary heart w-100" type="submit">';
      baloonContent += '<svg class="svg-inline--fa fa-heart fa-w-16 fa-fw text-white" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">';
      baloonContent += '<path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path>';
      baloonContent += '</svg>';
      baloonContent += '</button>';
      baloonContent += `<input type="hidden" name="authenticity_token" value="${  $(".spotFlowList").data("authenticity-token")  }">`;
      baloonContent += `<input type="hidden" name="spot_id" value="${  spotList[i].jc_id  }"></form>`;
      baloonContent += `<form data-target="map-marker.remove" data-action="ajax:success->map-marker#removed" class="button_to" method="post" action="/ja/my_plan/favorite_spots/${  spotList[i].jc_id  }" data-remote="true">`
      baloonContent += '<input type="hidden" name="_method" value="delete">';
      baloonContent += '<button class="btn btn-primary heart w-100 text-danger" type="submit">';
      baloonContent += '<svg class="svg-inline--fa fa-heart fa-w-16 fa-fw" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">';
      baloonContent += '<path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>';
      baloonContent += '</svg></button>';
      baloonContent += `<input type="hidden" name="authenticity_token" value="${  $(".spotFlowList").data("authenticity-token")  }">`;
      baloonContent += '</form>';
      baloonContent += '<div class="btns">';
      baloonContent += `<a href="/${  spotList[i].locale  }/spot_search/${  spotList[i].id  }" class="btn btn-primary detail" role="button">${  $(".spotFlowList").data("detail")  }</a>`;
      baloonContent += '</div>';
      baloonContent += '</div>';


      // Marker & infoWindow生成
      marker[i] = new window.google.maps.Marker({position: markerLatLng, map, icon: markerImg});
      infoWindow[i] = new window.google.maps.InfoWindow({content: baloonContent});
      markerEvent(i);
    }
    // Create - Adjust Map
    let minX = marker[0].getPosition().lng();
    let minY = marker[0].getPosition().lat();
    let maxX = marker[0].getPosition().lng();
    let maxY = marker[0].getPosition().lat();
    for (let i = 0; i < spotList.length; i+=1) {
      const lt = marker[i].getPosition().lat();
      const lg = marker[i].getPosition().lng();
      if (lg <= minX) {
        minX = lg;
      }
      if (lg > maxX) {
        maxX = lg;
      }
      if (lt <= minY) {
        minY = lt;
      }
      if (lt > maxY) {
        maxY = lt;
      }
    }
    const sw = new window.google.maps.LatLng(maxY, minX);
    const ne = new window.google.maps.LatLng(minY, maxX);
    const bounds = new window.google.maps.LatLngBounds(sw, ne);
    map.fitBounds(bounds);

    // Create - Polyline
    if (this.polyline === true) {
      if (typeof this.flightPath !== 'undefined') {
        this.flightPath.setMap(null);
      }
      if (spotList.length >= 2) {
        const polylineList = [];
        for (let j = 0; j < spotList.length; j+=1) {
          const polylineLatLng = new window.google.maps.LatLng(spotList[j].lat, spotList[j].lng);
          polylineList.push(polylineLatLng);
        }
        this.flightPath = new window.google.maps.Polyline({path: polylineList, strokeColor: '#FF0000', strokeOpacity: 0.3, strokeWeight: 5});
        this.flightPath.setMap(map);
      }
    }
  }

  // URLのパラメータを取得し、言語切り替えリンクに設定する
  const baseUrlEn = $('#header_link_en').attr('href')
  const baseUrlJa = $('#header_link_ja').attr('href')
  $('#header_link_en').attr('href', baseUrlEn + window.location.search)
  $('#header_link_ja').attr('href', baseUrlJa + window.location.search)
})
