import { Controller } from '@hotwired/stimulus';
import { requestFavoriteSpots, sendFavoriteSpotUpdated, RECEIVE_EVENT } from './favorite_count_controller';

export default class extends Controller {
  static targets = ['add', 'remove'];

  connect() {
    document.addEventListener(RECEIVE_EVENT, this.setState, false);
    requestFavoriteSpots();
  }

  disconnect() {
    document.removeEventListener(RECEIVE_EVENT, this.setState, false);
  }

  setState = ({ detail: ids }) => {
    if (ids.includes(this.id)) {
      this.addTarget.classList.add('d-none');
      this.removeTarget.classList.remove('d-none');
    } else {
      this.addTarget.classList.remove('d-none');
      this.removeTarget.classList.add('d-none');
    }
  };

  added() {
    this.addTarget.classList.add('d-none');
    this.removeTarget.classList.remove('d-none');
    sendFavoriteSpotUpdated();
    // カスタマイズ開始
    if (this.subdomain === 'true') return;
    const url = `/${this.data.get('locale')}/add_spot_to_plan/list`;
    // sendFavoriteSpotUpdated()で非同期処理後にお気に入り数が返却されるため、ポップアップ表示を少し遅らせる
    setTimeout(function f() {
      $.ajax({
        url,
        type: 'GET',
        timeout: 3000,
      });
    }, 300);
    // カスタマイズ終了
  }

  removed() {
    this.addTarget.classList.remove('d-none');
    this.removeTarget.classList.add('d-none');
    sendFavoriteSpotUpdated();
  }

  get id() {
    return parseInt(this.data.get('id'), 10);
  }

  // カスタマイズ開始
  get locale() {
    return this.data.get('locale');
  }

  get subdomain() {
    return this.data.get('subdomain');
  }

  // カスタマイズ終了
}
