import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['categories', 'seasons', 'categoriesButton', 'seasonsButton'];
  }

  categories(event) {
    event.preventDefault();
    this.seasonsTarget.classList.add('d-none');
    this.categoriesTarget.classList.remove('d-none');
    this.seasonsButtonTarget.classList.remove('active');
    this.categoriesButtonTarget.classList.add('active');
  }

  seasons(event) {
    event.preventDefault();
    this.seasonsTarget.classList.remove('d-none');
    this.categoriesTarget.classList.add('d-none');
    this.seasonsButtonTarget.classList.add('active');
    this.categoriesButtonTarget.classList.remove('active');
  }
}
