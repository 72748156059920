import $ from 'jquery';

$(function initSpotShow() {
  if ($('.page-spot_search-show').length > 0) {

    // Slide Show for Main Image
    $('.js-img-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true
    });

    if ($('#single-spot-map')) {
      const coordinates = $('#single-spot-map').data();
      const map = new window.google.maps.Map(document.getElementById('single-spot-map'), {
        center: coordinates,
        zoom: 13
      });
      // eslint-disable-next-line no-new
      new window.google.maps.Marker({
        position: coordinates,
        map
      });
    }
  }
});// Document Ready
