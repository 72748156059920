import $ from 'jquery';
import * as utils from '../utils';
import Swiper from 'swiper/bundle';

let resizeTimerTop = false;
let mvShowTop;

function topPage() {
  function getCourseStyle() {
    const w = utils.winW();
    const bp = 991;
    const ua = utils.ua();
    return w > bp && ua === 'pc' ? 'slide' : 'stack';
  }

  function adjustModelCourseStyle() {
    const $t = $('.course-list');
    const style = getCourseStyle();

    if (style === 'slide') {
      $t.removeClass('course-stack-mode-elm');
      $t.addClass('course-slide-mode-elm');
    } else if (style === 'stack') {
      $t.removeClass('course-slide-mode-elm');
      $t.addClass('course-stack-mode-elm');
    }
  }

  // ** FUNCTION
  // --------------------------------
  function slideMv() {
    const $active = $('.js-top-mv-slider > *.active');
    const $next = $active.next().length ? $active.next() : $('.js-top-mv-slider > *:first');
    $active.fadeOut(1000).removeClass('active');
    $next.fadeIn(1000).addClass('active');
  }

  // ** SETTING
  // --------------------------------
  (function init() {
    // Set modelcourse style when this page loaded.
    adjustModelCourseStyle();
    // Start automatic MV show
    mvShowTop = setInterval(function f() {
      slideMv();
    }, 5000);
  })();

  // ** EVENT
  // --------------------------------
  // Window Resize
  $(window).on('resize orientationchange', function f() {
    if (resizeTimerTop !== false) clearTimeout(resizeTimerTop);
    resizeTimerTop = setTimeout(function f2() {
      adjustModelCourseStyle();
    }, 200);
  });

  // Window Focus - Mv slyde show re-start
  $(window).on('focus', function focus() {
    mvShowTop = setInterval(function f() {
      slideMv();
    }, 5000);
  });

  // Window Focus - Mv slyde show stop
  $(window).on('blur', function blur() {
    clearInterval(mvShowTop);
  });

  // Click course area
  $('.js-wrap-link').on('click', function f() {
    if ($(this).hasClass('course-slide-mode-elm')) {
      const url = $(this).find('.link-btn a').attr('href');
      window.location.href = url;
    }
  });

  // Mouseenter course area
  $('.js-open-slider').on('mouseenter', '.course-slide-mode-elm', function f() {
    const $detail = $(this).find('.detail');
    const $pic = $(this).find('.pic');
    const $ttl = $(this).find('.ttl-upper');
    $detail.show();
    $detail.stop().animate({ left: 0 });
    $pic.stop().animate({ left: '40%' });
    $ttl.fadeOut(400);
  });

  // Mouseleave course area
  $('.js-open-slider').on('mouseleave', '.course-slide-mode-elm', function f() {
    const $detail = $(this).find('.detail');
    const $pic = $(this).find('.pic');
    const $ttl = $(this).find('.ttl-upper');
    $detail.stop().animate({ left: '-40%' });
    $pic.stop().animate({ left: 0 });
    $ttl.fadeIn(400);
  });
}

if ($('.page-top').length > 0) {
  $(function f() {
    if ($('.page-top').length) {
      topPage();
    }
  });
}

function updateAreaSelector(type) {
  const $target = $('.area-selector-ui__items');
  const targetActiveClassName = 'active';
  const targetHideClassName = 'd-none';
  // ナビが開いているなら or windowをresizeされたなら
  if ($target.hasClass(targetActiveClassName) || type === 'windowResize') {
    $target.removeClass(targetActiveClassName).addClass(targetHideClassName);
  } else {
    $target.removeClass(targetHideClassName).addClass(targetActiveClassName).hide().fadeIn(300);
  }
}

if ($('.area-selector-ui').length) {
  let updateAreaSelectorTimer = false;

  $(document).on('click', '.area-selector-ui__sp-toggler', function f() {
    updateAreaSelector();
    return false;
  });

  $(window).on('resize', function r() {
    if (updateAreaSelectorTimer !== false) clearTimeout(updateAreaSelectorTimer);
    updateAreaSelectorTimer = setTimeout(function f() {
      updateAreaSelector('windowResize');
    }, 200);
  });
}

$(function initTop() {
  if ($('.modal.instagram').length > 0) {
    // modal open
    $('#instagram_modal').on('show.bs.modal', function modalShow(e) {
      const $image = $('#instagram_modal .modal-instagram-img');
      const dispImgPath = $(e.relatedTarget).data('big-img');

      $image.attr('src', dispImgPath);
    });
  }
});

// ** swiper
// --------------------------------
if (document.querySelector('.top-swiper-container') !== null) {
  const topSwiper = new Swiper('.top-swiper-container', {
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    spaceBetween: 15,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: '.top-swiper-container .swiper-button-next',
      prevEl: '.top-swiper-container .swiper-button-prev',
    },
  });
}
