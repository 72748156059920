import Swiper from 'swiper/bundle';

// ** swiper
// --------------------------------
if (document.querySelector('.course-swiper-container') !== null) {
  // eslint-disable-next-line no-unused-vars
  const courseSwiper = new Swiper('.course-swiper-container', {
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.course-swiper-container .swiper-button-next',
      prevEl: '.course-swiper-container .swiper-button-prev',
    },
  });
}
