function ua() {
  const device = navigator.userAgent;
  if (
    (device.indexOf('Android') > 0 && device.indexOf('Mobile') > 0) ||
    device.indexOf('iPhone') > 0 ||
    device.indexOf('iPod') > 0 ||
    (device.indexOf('Windows') > 0 && device.indexOf('Phone') > 0) ||
    (device.indexOf('Firefox') > 0 && device.indexOf('Mobile') > 0) ||
    device.indexOf('BlackBerry') > 0
  ) {
    return 'sp';
  }
  if (
    device.indexOf('iPad') > 0 ||
    device.indexOf('Kindle') > 0 ||
    device.indexOf('Silk') > 0 ||
    (device.indexOf('Android') > 0 && device.indexOf('Mobile') < 0) ||
    (device.indexOf('Windows') > 0 &&
      device.indexOf('Touch') > 0 &&
      device.indexOf('Tablet PC') < 0) ||
    (device.indexOf('Firefox') > 0 && device.indexOf('Tablet') > 0) ||
    device.indexOf('PlayBook') > 0
  ) {
    return 'tb';
  }
  return 'pc';
}

function winW() {
  return Number(window.innerWidth ? window.innerWidth : $(window).width());
}

function winH() {
  return Number(window.innerHeight ? window.innerHeight : $(window).height());
}

export { ua, winH, winW };
