import $ from 'jquery';

if ($('.js-scroll-to').length > 0) {
  $('.js-scroll-to').on('click', function jsScroll() {
    // opt => target, speed, easing
    const $t = $(this);
    const opt = $t.data();
    const targetPosition = opt.target ? $(opt.target).offset().top : $('body').offset().top;
    const offset = opt.offset ? opt.offset : 0;
    const speed = opt.speed ? opt.speed : 'slow';
    const easing = opt.easing ? opt.easing : 'swing';

    $('html, body').animate({ scrollTop: targetPosition + offset }, speed, easing);
  });
}

$('.pageTop').on('click', function jsScroll() {
  const opt = $.extend({
    target: ".scroll",
    speed: "slow",
    easing: "swing",
    offset: 0
  }, {target: '.pageTop a', offset: 0});
  $('html,body').animate({scrollTop: $($('.pageTop a').attr("href")).offset().top - opt.offset}, opt.speed,opt.easing);
  return false;
});
