import Rails from '@rails/ujs';
// import * as ActiveStorage from '@rails/activestorage';
import 'bootstrap';

import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'slick-carousel';
import '@fortawesome/fontawesome-free/js/all';
import '../controllers';
import '../sprinkles';
import '../channels';

import '../japan_concierge/i18n/en';
import '../japan_concierge/i18n/fr';
import '../japan_concierge/i18n/id';
import '../japan_concierge/i18n/ja';
import '../japan_concierge/i18n/ko';
import '../japan_concierge/i18n/th';
import '../japan_concierge/i18n/vi';
import '../japan_concierge/i18n/zh-CN';
import '../japan_concierge/i18n/zh-TW';
import './server_rendering'

global.$ = $

Rails.start();
// ActiveStorage.start();
