import $ from 'jquery';

$('#fav-spots-popover').popover({ html: true });

$('#fav-spots-popover').on('shown.bs.popover', function updateFavSpotsCount() {
  $('#popover_my_plan_count').html($('#my_plan_count').html());
  $(`#${ $(this).attr('aria-describedby')}`).on('click', function stopPopoverHide(event) {
    event.stopPropagation();
  });
});

$('#fav-spots-popover').on('click', function stopPopoverHide(event) {
  event.stopPropagation();
});

function closeFavCountPopOver() {
  $('#fav-spots-popover').popover('hide');
}

$('body').on('click', function showClick() {
  closeFavCountPopOver();
});
