import $ from 'jquery';

$(function initSpot() {
  if ($('.page-spot_search').length > 0) {
    // Area Selector
    $('.js-all-area-ck').on('click', function areaClick() {
      const myLabel = $(this).find('label');
      const otherInputs = $(this).parent().find('input');
      const otherLabels = $(this).parent().find('label');

      if (myLabel.hasClass('cr')) {
        otherLabels.removeClass('cr');
        otherInputs.prop('checked', false);
      } else {
        otherLabels.addClass('cr');
        otherInputs.prop('checked', true);
      }
    });

    // Area Selector current state
    $('.js-toggle-state').on('click', function toggleState() {
      const label = $(this).find('label');
      const checkbox = $(this).find('input');

      if (label.hasClass('cr')) {
        checkbox.prop('checked', false);
        label.removeClass('cr');
        $('.js-all-area-ck > label').removeClass('cr');
      } else {
        checkbox.prop('checked', true);
        label.addClass('cr');
      }
    });

    $('.toggle-category').on('click', function uncheckGroup() {
      if (!this.checked) {
        $('.js-all-cat-ck').prop('checked', false);
      }
    });

    // Category Selector
    $('.js-all-cat-ck').on('change', function allCatChange() {
      const mainCatWrap = $(this).parent().parent();
      const subCatWraps = mainCatWrap.siblings();
      const subCatNum = subCatWraps.length;

      if (subCatNum > 0) {
        const inputs = subCatWraps.find('input');
        if (mainCatWrap.find('input').prop('checked')) {
          inputs.prop('checked', true);
        } else {
          inputs.prop('checked', false);
        }
      }
    });
  }
}); // Document ready

$(window).on('scroll', function loadNextPage() {
  const nextPageUrl = $('#infinite-scroll .next a').attr('href');
  if (nextPageUrl && $(window).scrollTop() > $(document).height() - $(window).height() - $('.Footer').height() - 60) {
    $('#infinite-scroll').html(`<p class="page-loading">Loading...<img src="${  $('#infinite-scroll').data('image')  }"></p>`);
    $.getScript(nextPageUrl);
  }
});
