import $ from 'jquery';

$(function init() {
  if ( $('.news-show-main-img-set').length ) {
    // slick for main image
    $('.news-show-main-img-set').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
    });
  }
});
