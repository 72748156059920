// [note]
// The modal element uses Bootstrap modal component.
// The following script only defines the behavior of the content inside the modal.

class HowtoModal {
  constructor(opts) {
    this.selector = {
      modal: document.querySelector(opts.selector.modal),
      pagerNext: document.querySelector(opts.selector.pagerNext),
      pagerPrev: document.querySelector(opts.selector.pagerPrev),
      contents: document.querySelectorAll(opts.selector.content)
    };

    this.config = {
      startPageIndex: 0,
      endPageIndex: this.selector.contents.length - 1,
      pagerDisabledClassName: opts.config.pagerDisabledClassName,
      contentHideClassName: opts.config.contentHideClassName
    };

    this.state = {
      currentPageIndex: opts.state.currentPageIndex
        ? opts.state.currentPageIndex
        : 0,
      nextPagerState: true,
      prevPagerState: false
    };

    this.selector.pagerPrev.addEventListener('click', () => {
      this.countDownPageNum();
      this.updateDesign();
      this.updateContents();
    });

    this.selector.pagerNext.addEventListener('click', () => {
      this.countUpPageNum();
      this.updateDesign();
      this.updateContents();
    });
  }

  countUpPageNum() {
    if (this.state.currentPageIndex < this.config.endPageIndex) {
      this.state.currentPageIndex += 1;
    }
  }

  countDownPageNum() {
    if (this.state.currentPageIndex > this.config.startPageIndex) {
      this.state.currentPageIndex -= 1;
    }
  }

  updateDesign() {
    this.selector.pagerPrev.classList.remove(
      this.config.pagerDisabledClassName
    );
    this.selector.pagerNext.classList.remove(
      this.config.pagerDisabledClassName
    );
    if (this.state.currentPageIndex === this.config.startPageIndex) {
      this.selector.pagerPrev.classList.add(this.config.pagerDisabledClassName);
    }
    if (this.state.currentPageIndex === this.config.endPageIndex) {
      this.selector.pagerNext.classList.add(this.config.pagerDisabledClassName);
    }
  }

  updateContents() {
    this.selector.contents.forEach(content => {
      content.classList.add(this.config.contentHideClassName);
    });

    this.selector.contents[this.state.currentPageIndex].classList.remove(
      this.config.contentHideClassName
    );
  }

  init() {
    this.updateDesign();
    this.updateContents();
  }
}

export default HowtoModal;
