import $ from 'jquery';

$(function initMovie() {
  if ($('.modal.movies').length > 0) {
    // modal open
    $('#movies_modal').on('show.bs.modal', function modalShow(e) {
      const $title = $('#movies_modal .modal-title');
      const $iframe = $('#movies_modal iframe');
      const newAreaId = $(e.relatedTarget).data('area-id');
      const newArea = $(e.relatedTarget).data('area');
      const newName = $(e.relatedTarget).data('name');
      const newTitle = `<span class="modal-title-label modal-title-label--type_${  newAreaId  }">${  newArea  }</span><span class="modal-title-name">${  newName  }</span>`;
      const newMoviePath = $(e.relatedTarget).attr('href');
      $title.html(newTitle);
      $iframe.attr('src', newMoviePath).fadeIn(400);
    });

    // modal close
    $('#movies_modal').on('hide.bs.modal', function modalHide() {
      const $title = $('#movies_modal .modal-title');
      const $iframe = $('#movies_modal iframe');
      $title.html('');
      $iframe.attr('src', '');
    });
  }
});
