import $ from 'jquery';

let myMap;
let myInfoWindow;
let myHeatmapLayer;
const oriPhotoData = [];
let targetPhotoData = [];
let targetPhotoDataSortedByPotential = [];
const param = {maxPics: 12, picsOpacity:1.0, radius: 100, intensity: 10, minMonth: 1, maxMonth: 12, minHour: 0, maxHour: 24, minDistance: 0, minRain: 0};
// マーカー表示処理
// Marker display processing
const makers = {};

function geoDistance(point1, point2){
  // km単位で地表面のpoint1-2間の距離を近似的に返す
  // Approximately return the distance between points 1 and 2 on the ground surface in meters
  return 1000 * Math.sqrt( (((point1.lat() - point2.lat()) / 0.0111) ** 2) + (((point1.lng() - point2.lng()) / 0.0091) ** 2));
}

function meterToPixel(x){
  // meter値が地図上で何ピクセルに相当するかを返す
  // Approximately return the distance between points 1 and 2 on the ground surface in meters
  const mapDiagonalD = geoDistance(myMap.getBounds().getNorthEast(), myMap.getBounds().getSouthWest());
  const scrDiagonalD = Math.sqrt(($("#dispMap").height() ** 2) + ($("#dispMap").width() ** 2));
  return Math.ceil(x * scrDiagonalD / mapDiagonalD);
}

function modifyMap(){
  // ヒートマップのプロパティを修正する
  // Modify the heat map properties
  if(myHeatmapLayer) myHeatmapLayer.setOptions({
    radius: meterToPixel(param.radius),
    maxIntensity: param.intensity
  });
}

function setPhotoData(){
  // テキストをparseする
  if(!param.minYear || (param.minYear>=0 && (param.minYear >= param.maxYear))) param.minYear = 9999;
  if(!param.maxYear || (param.maxYear>=0 && (param.minYear >= param.maxYear))) param.maxYear = 0;
  if(!param.minDistance || (param.minDistance>=0 && (param.minDistance >= param.maxDistance))) param.minDistance = 0;
  if(!param.maxDistance || (param.maxDistance>=0 && (param.minDistance >= param.maxDistance))) param.maxDistance = 0;
  if(!param.minRain || (param.minRain>=0 && (param.minRain >= param.maxRain))) param.minRain = 0;
  if(!param.maxRain || (param.maxRain>=0 && (param.minRain >= param.maxRain))) param.maxRain = 0;

  // 南から北へデータソート（検索を高速化するため）
  oriPhotoData.sort(function photoDataSort(a,b){
    if(a.lat > b.lat) return 1;
    return -1;
  });

  // 与えられたデータに応じて地図の境界を設定する
  const bounds = new window.google.maps.LatLngBounds(oriPhotoData[0].LatLng);
  for(let i=1 ; i<oriPhotoData.length ; i+=1) {
    bounds.extend(oriPhotoData[i].LatLng);
  }
  myMap.fitBounds(bounds);

}

function findPhotoWithClosestLat(lat, photoData){
  // 与えられた写真集団の中で，最も近しい緯度の写真を二分探索で見つける
  // In the given photograph group, find the photograph with the closest latitude by binary search
  let low = 0;
  let high = photoData.length-1;
  if(lat <= photoData[0].LatLng.lat()) return 0;
  if(photoData[high].LatLng.lat() <= lat) return high;
  while(high - low > 2){
    const i = Math.floor((low + high) / 2);
    if(photoData[i].LatLng.lat() < lat){
      low = i;
    } else{
      high = i;
    }
  }
  if(lat - photoData[low].LatLng.lat() < photoData[high].LatLng.lat() - lat)	return low;
  return high;
}

function kernelDensity(point){
  function epanechnikov(dis){
    // ガウス関数の値を返す
    // Returns the value of Gaussian function
    return (dis < param.radius) ? (1 -((dis / param.radius) ** 2)) : 0;
  }
  // 任意の地点のカーネル値を求める
  // Find the kernel value of an arbitrary point
  const low = findPhotoWithClosestLat(point.lat() - param.radius * 0.000008983148616, targetPhotoData);		// 検索範囲下限 Search range lower limit
  const high = findPhotoWithClosestLat(point.lat() + param.radius * 0.000008983148616, targetPhotoData);		// 検索範囲上限 Search range upper limit

  let x = 0;
  for(let i = low; i <= high; i+=1){
    x += epanechnikov(geoDistance(point, targetPhotoData[i].LatLng)) * targetPhotoData[i].weight;
  }
  return x;
}

function drawMap(){
  // ヒートマップおよびその上の写真を描画する
  // Draw a heat map and the photos above it
  const myHeatmapLatLngs = [];
  targetPhotoData = [];

  // ヒートマップの対象写真を抽出する
  // Extract the target photograph of the heat map
  for(let i=0 ; i<oriPhotoData.length ; i+=1) {
    myHeatmapLatLngs.push(oriPhotoData[i].LatLng);	// なぜか/1必要
    targetPhotoData.push(oriPhotoData[i]);
  }

  // 各地点のポテンシャルの計算
  // Calculating the potential of each point
  for(let i=0; i<targetPhotoData.length; i+=1){
    targetPhotoData[i].potential = kernelDensity(targetPhotoData[i].LatLng);
  }

  // ヒートマップ対象の写真（緯度順）をポテンシャル順におきかえたものも別途用意しておく
  // We also prepare separately those which replace the photos (latitudinal order) subject to the heat map in the order of potential
  targetPhotoDataSortedByPotential = targetPhotoData.concat();		// 配列のコピー（中身のみ） Copy of array (contents only)
  targetPhotoDataSortedByPotential.sort(function pohotoDataSort(a,b){
    if(a.potential < b.potential) return 1;
    return -1;
  });

  // ヒートマップレイヤを用意
  // Heat map layer prepared
  if(myHeatmapLayer) myHeatmapLayer.setMap(null);		// 初回以外はヒートマップを一旦消去 Clear the heat map once except for the first time
  myHeatmapLayer = new window.google.maps.visualization.HeatmapLayer({
    data: myHeatmapLatLngs,
    radius: meterToPixel(param.radius),
    maxIntensity: param.intensity,
    dissipating: true,
    opacity: 0.4,
    map: myMap
  });

}

function setHeatMap(list) {
  list.forEach(function setOriPhotoData(item){
    oriPhotoData.push({
      LatLng: new window.google.maps.LatLng(item.lat, item.lng),
      lat: item.lat,
      distance: 2000,
      rain: 10,
      weight: 1
    });
  });

  // 地図の下準備(中心，ズーム値は仮)
  // Prepare the map (center, zoom value is temporary)
  myMap = new window.window.google.maps.Map(document.getElementById("dispMap"));

  myInfoWindow = new window.google.maps.InfoWindow();

  window.google.maps.event.addListener(myMap, "zoom_changed", function zoomChanged(){
    modifyMap();
  });

  if (oriPhotoData.length > 0) {
    setPhotoData();
    window.google.maps.event.addListenerOnce(myMap, 'idle', function idleMap(){drawMap();});
  }

  // 地図の境界が画定したあとでidleイベントが起こり，drawMap()が実行される
  // An idle event occurs after the map boundary has been defined, and drawMap () is executed
}

// 以下，サブルーチン
// Subroutines
function removeHeatMapLayer() {
  if(myHeatmapLayer) myHeatmapLayer.setMap(null);
}

// マーカー削除処理
// Marker deletion processing
function removeMapMarker(kind) {
  (makers[kind] || []).forEach(function setMarker(marker) { marker.setMap(null); });
  makers[kind] = null;
}

function creategoogleMarker(latlng, spotId, markerImageUrl, locale) {
  const marker = new window.google.maps.Marker({
    position: latlng,
    map: myMap,
    icon: markerImageUrl
  });

  window.google.maps.event.addListener(marker, 'click', function clickMarker() {
    myInfoWindow.close();
    $.ajax({
      url: `/${locale}/spot_search/${spotId}.js`,
      dataType: 'html',
      success: function setThumb(result) {
        myInfoWindow.setContent(result);
        myInfoWindow.open(myMap, marker);
      }
    });
  });

  return marker;
}

// マーカー生成
// Marker generation
function creategoogleMapMarker(area, kind, locale) {
  if ( makers[kind] ) {
    makers[kind].forEach(function displayMakerOnMap(marker) { marker.setMap(myMap); });
  } else {
    $.ajax({
      url: `/${locale}/area/${area}.json?kind=${kind}`,
      dataType: 'json',
      success: function loadMaker(result) {
        makers[kind] = result.spots.map(function buildMaker(spot) {
          const latlng = new window.google.maps.LatLng(spot.lat, spot.lng);
          return creategoogleMarker(latlng, spot.id, result.markerImage, locale);
        });
      }
    });
  }
}

// Areaページカテゴリ押下時の処理
$(function init() {
  $(".select_category_link").on('click', function clickSelectCategoryLink() {
    const area = $(this).data('area');
    const category = $(this).data('category');
    const locale = $(this).data('locale');

    if ( $(`#select_category_${category}`).hasClass("active") ) {
      $(`#select_category_${category}`).removeClass("active");
      removeMapMarker(category);
    } else {
      creategoogleMapMarker(area, category, locale);
      $(`#select_category_${category}`).addClass("active");
    }
  });

  if ($('#dispMap').length > 0) {
    $.ajax({
        url: '/heat_map/list',
        type:'POST',
        dataType: 'json',
        data : {key : $('#heatMapKey').text() },
        timeout:3000,
    }).done(function success(data) {
      setHeatMap(data.list)
    })

    $('#select_category_heat_map').on('click', function toggleHeatMap(event) {
      event.preventDefault();

      if ( $('#select_category_heat_map').hasClass('active') ) {
        $('#select_category_heat_map').removeClass('active');
        removeHeatMapLayer();
      } else {
        $('#select_category_heat_map').addClass('active');
        drawMap();
      }
    });
  }
});
