import $ from 'jquery';

$(function initConcierge() {

  /*----------------------------------------
      Global Setting
  ----------------------------------------*/
  // サブミットボタンの指定
  const $doneBtn = $('#searchBtn');
  // レーダーチャートの初期値
  const myProfile = {
    'weight': [0.2, 0.2, 0.2, 0.2, 0.2]
  };

  // レーダーチャート - 描画
  function drawRadarChart(canvas, val, label) {
    // 与えられたcanvasにレーダーチャートを描く（val，labelは値，ラベルの列）
    const cW = canvas.width; // キャンバス横サイズ
    const cH = canvas.height; // キャンバス縦サイズ
    const rdSize = Math.min(cH / 2 - 10, cW / 2 - 20);
    const ctx = canvas.getContext('2d');
    const n = val.length;

    // 消去
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, cW, cH);

    // レーダー内部
    ctx.fillStyle = '#ffcc00';
    ctx.beginPath();
    ctx.moveTo(cW / 2, cH / 2 - rdSize * val[0]);
    for (let i = 1; i < n; i+=1) {
      ctx.lineTo(cW / 2 + rdSize * Math.sin(i / n * Math.PI * 2) * val[i], cH / 2 - rdSize * Math.cos(i / n * Math.PI * 2) * val[i]);
    }
    ctx.closePath();
    ctx.fill();

    // 外周・目盛り
    ctx.fillStyle = '#CCCCCC';
    ctx.strokeStyle = '#CCCCCC';
    ctx.beginPath();
    ctx.moveTo(cW / 2, cH / 2 - rdSize);
    for (let i = 1; i < n; i+=1) {
      ctx.lineTo(cW / 2 + rdSize * Math.sin(i / n * Math.PI * 2), cH / 2 - rdSize * Math.cos(i / n * Math.PI * 2));
    }
    ctx.closePath();
    ctx.stroke();
    for (let i = 0; i < n; i+=1) {
      ctx.beginPath();
      ctx.moveTo(cW / 2, cH / 2);
      ctx.lineTo(cW / 2 + rdSize * Math.sin(i / n * Math.PI * 2), cH / 2 - rdSize * Math.cos(i / n * Math.PI * 2));
      ctx.closePath();
      ctx.stroke();
      for (let j = 1; j <= 5; j+=1) {
        ctx.beginPath();
        ctx.arc(cW / 2 + rdSize * Math.sin(i / n * Math.PI * 2) * j / 5, cH / 2 - rdSize * Math.cos(i / n * Math.PI * 2) * j / 5, 2, 0, Math.PI * 2, true);
        ctx.fill();
      }
    }
    // ラベル
    ctx.font = "11px Helvetica, Arial, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', MS PGothic, sans-serif";
    ctx.textAlign = 'center';
    ctx.fillStyle = '#000';
    for (let i = 0; i < n; i+=1) {
      ctx.fillText(label[i], cW / 2 + rdSize * Math.sin(i / n * Math.PI * 2) * 1.3, cH / 2 - rdSize * Math.cos(i / n * Math.PI * 2) * 1.1 + 5);
    }
  }

  // レーダーチャート - 項目内容の生成
  function showProfile() {
    function weight2pos(weight) {
      return Math.sqrt(weight) * 0.8 + 0.2;
    }
    // 現在の嗜好を表示する
    const language = $('#rcCanvas').data('locale');
    let label;
    if (language === 'en') {
      label = ['Ａ.Entertainment', 'Ｂ.Culture', 'Ｃ.Shopping', 'Ｄ.Art', 'Ｅ.Nature'];
    } else {
      label = ['Ａ.エンタメ', 'Ｂ.歴史・文化', 'Ｃ.ショッピング', 'Ｄ.アート', 'Ｅ.自然'];
    }

    // レーダーチャートを描く
    const pos = [];
    for (let i = 0; i < myProfile.weight.length; i+=1) {
      pos.push(weight2pos(myProfile.weight[i]));
    }
    drawRadarChart(document.getElementById('rcCanvas'), pos, label);
  }

  /*----------------------------------------
      FUNC - レーダーチャートに関わる関数
  ----------------------------------------*/
  function initRadarChart() {
    // レーダーチャートの設定
    let targetAxis = -1; // 軸
    const rect = $('#rcCanvas').offset();
    const cW = $('#rcCanvas').width(); // キャンバス横サイズ
    const cH = $('#rcCanvas').height(); // キャンバス縦サイズ
    const rdSize = Math.min(cH/2-15,cW/2-25); // レーダーチャートのサイズ

    function setRadarChart(e) {
      function pos2weight(pos){
        return ((pos - 0.2) / 0.8) ** 2;
      }

      // if (e.originalEvent.changedTouches) e = e.originalEvent.changedTouches[0];
      const angle = Math.atan2(e.pageX - rect.left - cW/2, rect.top + cH/2 - e.pageY);
      let pos = (Math.sqrt(((e.pageX - rect.left - cW / 2) ** 2) + ((rect.top + cH/2 - e.pageY) ** 2)) * Math.cos(angle - targetAxis/myProfile.weight.length*Math.PI*2)) / rdSize;
      pos = Math.min(Math.max(pos,0.2),1);
      for(let i=0; i<myProfile.weight.length; i+=1){
        if (i !== targetAxis) myProfile.weight[i] = (myProfile.weight[targetAxis] === 1) ?
          (1-pos2weight(pos))/myProfile.weight.length : myProfile.weight[i] * (1-pos2weight(pos)) / (1-myProfile.weight[targetAxis]);
      }
      myProfile.weight[targetAxis] = pos2weight(pos);
      showProfile();
    }

    $("#rcCanvas").bind({
      'touchstart mousedown': function(e){
        e.preventDefault();
        // if (e.originalEvent.changedTouches) e = e.originalEvent.changedTouches[0];
        const angle = (Math.atan2(e.pageX - rect.left - cW/2, rect.top + cH/2 - e.pageY) + 2*Math.PI) % (2*Math.PI);
        const degree = angle * myProfile.weight.length / (2*Math.PI);
        if (degree % 1 <= 0.4 || degree % 1>= 0.6){
          targetAxis = Math.round(degree) % myProfile.weight.length;
        }
      },
      'touchmove mousemove': function(e){
        e.preventDefault();
        if(targetAxis>=0) setRadarChart(e);
      },
      'touchend mouseup': function(e){
        e.preventDefault();
        if(targetAxis<0) return;
        setRadarChart(e);
        targetAxis = -1;
      }
    });
  } // initRadarChart()

  // ボタンによるプロファイルの増減処理
  function changeProfile(index, num) {
    // 指定された現在のプロファイル値をチェック
    let tmpChgProfile = myProfile.weight[index] + num;
    if (tmpChgProfile > 1) {
      tmpChgProfile = 1;
    } else if (tmpChgProfile < 0) {
      tmpChgProfile = 0;
    }

    myProfile.weight[index] = tmpChgProfile;
    // 変更されなかったカテゴリに割り当てるウェイトの数値合計
    const totalPoint = 1 - myProfile.weight[index];
    // 変更されなかったカテゴリの割合値の合計
    let totalWeight = 0;
    for (let i = 0; i < myProfile.weight.length; i+= 1) {
      if (i !== index) {
        totalWeight += myProfile.weight[i];
      }
    }

    for (let i = 0; i < myProfile.weight.length; i+= 1) {
      if (i !== index) {
        if (totalWeight > 0) {
          myProfile.weight[i] = totalPoint * (myProfile.weight[i] / totalWeight);
        } else if (totalWeight <= 0) {
          myProfile.weight[i] = totalPoint * (1 / (myProfile.weight.length - 1));
        } else {
          myProfile.weight[i] = 0;
        }
      }
    }
    showProfile();
  } // changeProfile()

  if ($('#rcCanvas').length > 0) {
    /*----------------------------------------
        Init
    ----------------------------------------*/
    // スライダーの初期化（jquery ui sliderの）
    if ($.ui) {
      $('.sliderSet .slider').slider({
        value: 0,
        min: -2,
        max: 2,
        step: 0.1,
      });
    }

    // レーダーチャートの初期化
    initRadarChart();
    // レーダーチャートないの項目などの初期化
    showProfile();

    /*----------------------------------------
        Event - Searchボタンのクリック
    ----------------------------------------*/
    $doneBtn.on('click', function doneBtnClick() {
      // 準備
      const tasteArr = []; // スライダーの値格納用
      const formAreaArr = []; // 人気スポットチェック格納用
      const url = 'concierge_detail.html'; // 遷移先のURL

      tasteArr.length = 0;
      // パラメータの設定 - エリア
      const area = $("select[name='starting_point']").val();
      // パラメータの設定 - 旅行時間
      const tourTime = $("select[name='tour_time']").val();
      // パラメータの設定 - 開始時刻
      const startTime = $("select[name='start_time']").val();
      // パラメータの設定 - 曜日
      const days = $("select[name='days']").val();
      // パラメータの設定 - 歩く速度
      const speed = $("select[name='speed']").val();
      // パラメータの設定 - スライダー
      $('.slider').each(function sliderEach() {
        tasteArr.push($(this).slider('value'));
      });

      // パラメータの設定 - レーダーチャート
      // まだ未設定
      $('.imgCheckSet input[name="add_fav"]:checked').each(function check(index, checkbox) {
        formAreaArr.push($(checkbox).val());
      });

      // パラメータの合算
      const params = `${'?' +
        'ptn=1&' +
        'place='}${  area  }&` +
        `defaultTourTime=${  tourTime  }&` +
        `start_time=${  startTime  }&` +
        `days=${  days  }&` +
        `speed=${  speed  }&` +
        `taste=${  tasteArr.toString()  }&` +
        `weight=${  myProfile.weight.toString()  }&` +
        `visit_ids=${  formAreaArr.toString()}`;
      // 遷移先のURLをaタグに指定
      $(this).attr('href', url + params);

    }); // click

    /*----------------------------------------
        Event - レーダーチャート下のプラスマイナスボタンの操作
    ----------------------------------------*/
    $('#radarBtns a').click(function radarBtnsClick() {
      const changeNum = 0.2;
      const parent = $(this).parent().attr('class');

      switch (parent) {
        case 'btnsA':
          if ($(this).children().hasClass('fa-plus-square')) {
            changeProfile(0, changeNum);
            // alert('Aのプラス');
          } else if ($(this).children().hasClass('fa-minus-square')) {
            changeProfile(0, 0 - changeNum);
            // alert('Aのマイナス');
          }
          break;
        case 'btnsB':
          if ($(this).children().hasClass('fa-plus-square')) {
            changeProfile(1, changeNum);
            // alert('Bのプラス');
          } else if ($(this).children().hasClass('fa-minus-square')) {
            changeProfile(1, 0 - changeNum);
            // alert('Bのマイナス');
          }
          break;
        case 'btnsC':
          if ($(this).children().hasClass('fa-plus-square')) {
            changeProfile(2, changeNum);
            // alert('Cのプラス');
          } else if ($(this).children().hasClass('fa-minus-square')) {
            changeProfile(2, 0 - changeNum);
            // alert('Cのマイナス');
          }
          break;
        case 'btnsD':
          if ($(this).children().hasClass('fa-plus-square')) {
            changeProfile(3, changeNum);
            // alert('Dのプラス');
          } else if ($(this).children().hasClass('fa-minus-square')) {
            changeProfile(3, 0 - changeNum);
            // alert('Dのマイナス');
          }
          break;
        case 'btnsE':
          if ($(this).children().hasClass('fa-plus-square')) {
            changeProfile(4, changeNum);
            // alert('Eのプラス');
          } else if ($(this).children().hasClass('fa-minus-square')) {
            changeProfile(4, 0 - changeNum);
            // alert('Eのマイナス');
          }
          break;
        default:
          break;
      } // switch
    }); // click
  }
}); // Document ready
