import $ from 'jquery';

function SpotMap(elementMatcher, spots, Marker) {
  const map = new window.google.maps.Map(document.getElementById('spot-search-map'), {
    mapTypeId: window.google.maps.MapTypeId.ROADMAP
  });

  const infoWindow = new window.google.maps.InfoWindow();

  const markers = spots.map(function createMaker(spot) {
    return new Marker(spot, map, infoWindow);
  });

  const bounds = markers.reduce(function addPositionToBounds(bound, marker) {
    return bound.extend(marker.getPosition());
  }, new window.google.maps.LatLngBounds());

  this.focus = function focus() {
    map.fitBounds(bounds);
  };
}

function SpotSearchMarker(spot, map, infoWindow) {
  const marker = new window.google.maps.Marker({
    position: new window.google.maps.LatLng(spot.lat, spot.lng),
    map,
    id: spot.id,
    title: spot.name
  });

  marker.addListener('click', function markerListner() {
    infoWindow.close();
    $.ajax({
      url: `/spot_search/${  marker.get('id')  }.js`,
      dataType: 'html',
      success: function setInfoWindow(result) {
        infoWindow.setContent(result);
        infoWindow.open(map, marker);
      }
    });
  });

  return marker;
}

if ( $('.spot-search-map-modal').length > 0 ) {

  // 検索ボタン押下により動的にMAPボタン以下置き換えられる場合通常のJQueryではイベントが発火しない
  // 為、document全体にイベントを登録する必要がある
  $(document).on('click', '#spot_result_map', function mapClick(){
    $('.spot-search-map-modal').modal('show');
  });

  const modal = $('.spot-search-map-modal');
  modal.on('shown.bs.modal', function showSpotSearchMap() {
    const areaValues = $('input:hidden[id=q_area_id_eq_any_]').map(function areaEach() {
      return $(this).val();
    }).get();
    const categoryCalues = $('input:hidden[id=q_spot_categories_category_id_eq_any_]').map(function categoryEach() {
      return $(this).val();
    }).get();
    $.ajax({
      url: '/spot_search_map',
      type: "GET",
      data: {
        q: {
          translations_name_or_translations_description_cont: $('#q_translations_name_or_translations_description_cont').val(),
          spot_categories_category_id_eq_any: categoryCalues,
          area_id_eq_any: areaValues,
        }
      },
      dataType: "json"
    })
    .done(function success(data) {
      new SpotMap('#spot-search-map', data.spots, SpotSearchMarker).focus();
    })
    .fail(function fail() {
    })
  });
}
