import $ from 'jquery';
import HowtoModal from './HowtoModal';
import ApprovalRequiredFixBalloon from './ApprovalRequiredFixBalloon';

$(() => {
  // Run Howto Modal
  if ($('.howto-modal').length) {
    const howToModal = new HowtoModal({
      selector: {
        modal: '.howto-modal',
        pagerNext: '.howto-modal .howto-modal-pager__icon--type_right',
        pagerPrev: '.howto-modal .howto-modal-pager__icon--type_left',
        content: '.howto-modal .howto-cnt'
      },
      state: {
        currentPageIndex: 0
      },
      config: {
        contentHideClassName: 'd-none',
        pagerDisabledClassName: 'disabled'
      }
    });
    howToModal.init();
  }
});

const accessAttentionPopup = document.querySelector('[data-role="access-attention-popup"]');
if (accessAttentionPopup !== null) {
    const closer = accessAttentionPopup.querySelector('[data-role="access-attention-popup-closer"]');
    closer.addEventListener('click', () => {
      accessAttentionPopup.classList.remove('is-show');
      accessAttentionPopup.classList.add('is-hide');
    });
}

if (document.querySelector('[data-role="approval-required-fix-balloon"]') !== null) {
  const approvalRequiredFixBalloon = new ApprovalRequiredFixBalloon();
  approvalRequiredFixBalloon.init();
}